<template>
  <div :class="$vuetify.breakpoint.name === 'lg' ? 'pr-7 pl-7' : 'px-8'">
    <Snackbar ref="snackbar" />

    <v-dialog v-model="dialog.detail" width="600">
      <v-card>
        <v-card-title> Detail Log </v-card-title>

        <v-row no-gutters class="pa-4">
          <v-col cols="12">
            <table v-if="id && id.data">
              <tbody>
                <tr>
                  <td class="text-no-wrap grey--text text--darken-3 py-1 pr-4">
                    Company Name
                  </td>
                  <td class="py-1">{{ id.company.name }}</td>
                </tr>
                <tr>
                  <td class="text-no-wrap grey--text text--darken-3 py-1 pr-4">
                    Program Name
                  </td>
                  <td class="py-1">
                    {{ id.data.sync_member_redeem.program.title }}
                  </td>
                </tr>
                <tr>
                  <td class="text-no-wrap grey--text text--darken-3 py-1 pr-4">
                    Topic Name
                  </td>
                  <td class="py-1">
                    {{ id.data.sync_member_redeem.topic.name }}
                  </td>
                </tr>
                <tr>
                  <td class="text-no-wrap grey--text text--darken-3 py-1 pr-4">
                    Cron Type
                  </td>
                  <td class="py-1">{{ id.type }}</td>
                </tr>
                <tr>
                  <td class="text-no-wrap grey--text text--darken-3 py-1 pr-4">
                    Status
                  </td>
                  <td class="py-1">
                    <v-chip v-if="id.status == 'pending'" outlined color="error"
                      >Pending</v-chip
                    >
                    <v-chip
                      v-else-if="id.status == 'running'"
                      outlined
                      color="warning"
                      >Running</v-chip
                    >
                    <v-chip
                      v-else-if="id.status == 'success'"
                      outlined
                      color="success"
                      >Success</v-chip
                    >
                  </td>
                </tr>
                <tr>
                  <td class="text-no-wrap grey--text text--darken-3 py-1 pr-4">
                    Result
                  </td>
                  <td class="py-1">{{ id.note }}</td>
                </tr>
                <tr>
                  <td class="text-no-wrap grey--text text--darken-3 py-1 pr-4">
                    Cron Experiment
                  </td>
                  <td class="py-1">{{ id.attempt_number }}</td>
                </tr>
                <tr>
                  <td class="text-no-wrap grey--text text--darken-3 py-1 pr-4">
                    Created at
                  </td>
                  <td class="py-1">{{ id.created_at | datetime }}</td>
                </tr>
                <tr>
                  <td class="text-no-wrap grey--text text--darken-3 py-1 pr-4">
                    Running at
                  </td>
                  <td class="py-1">{{ id.running_at | datetime }}</td>
                </tr>
                <tr>
                  <td class="text-no-wrap grey--text text--darken-3 py-1 pr-4">
                    Finished at
                  </td>
                  <td class="py-1">{{ id.finish_at | datetime }}</td>
                </tr>
              </tbody>
            </table>

            <div class="text-center mt-4">
              <v-btn color="primary" @click="dialog.detail = false">
                Close
              </v-btn>
            </div>
          </v-col>
        </v-row>
      </v-card>
    </v-dialog>

    <!-- DIALOG CONFIRMATION RESET LICENSE -->
    <v-dialog v-model="dialog.confirm" width="450">
      <v-card>
        <v-card-title>
          Warning !
          <v-spacer></v-spacer>
          <v-icon size="30" @click="dialog.confirm = false" color="red">
            mdi-close-circle-outline
          </v-icon>
        </v-card-title>
        <v-card-text class="subtitle-1 font-weight-bold text-center">
          You will reset the queue. Do the reset?
        </v-card-text>
        <v-card-actions>
          <v-spacer></v-spacer>
          <v-btn
            :disabled="process.run"
            elevation="0"
            class="text-capitalize"
            @click="dialog.confirm = false"
          >
            Cancel
          </v-btn>
          <v-btn
            :loading="process.run"
            color="#F05326"
            elevation="0"
            class="white--text text-capitalize"
            @click="resetCron()"
          >
            Yes, Reset
          </v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>

    <div class="mt-5">
      <div class="title black--text mb-2">Cron Monitoring</div>
      <v-row justify="space-between" class="mt-2">
        <v-col cols="4">
          <div>
            <v-text-field
              solo
              filled
              dense
              flat
              :hide-details="true"
              placeholder="Search Cron Data . . ."
              autocomplete="off"
              v-model="searching"
              :readonly="process.run"
              clearable
              color="#F05326"
              @click:clear="searching = ''"
              prepend-inner-icon="mdi-magnify"
            >
            </v-text-field>
          </div>
        </v-col>
        <v-col cols="4" class="text-right">
          <div class="d-flex float-right">
            <v-btn
              v-if="false"
              depressed
              :loading="process.run"
              @click="showDialog()"
              elevation="0"
              color="#F05326"
              class="text-capitalize ml-5 white--text"
            >
              Generate voucher
            </v-btn>
            <v-btn
              v-if="false"
              depressed
              :loading="process.run"
              @click="filter"
              elevation="0"
              color="#F5F5F5"
              class="black--text text-capitalize ml-5"
            >
              Filter by
              <v-icon right>mdi-filter-variant</v-icon>
            </v-btn>
          </div>
        </v-col>
      </v-row>

      <div class="mt-3 mb-8">
        <v-skeleton-loader
          class="ma-auto"
          :loading="$store.state.process.run"
          :tile="false"
          transition="scale-transition"
          type="table-tbody"
        >
          <v-data-table
            class="table-list"
            hide-default-footer
            fixed-header
            :loading="$store.state.process.run || process.run"
            :headers="headers"
            :items="items.list"
            :items-per-page.sync="items.limit"
            :page.sync="items.page"
            :server-items-length="items.total"
            item-key="id"
            :footer-props="{
              itemsPerPageText: 'Data per halaman',
              'items-per-page-options': [5, 10, 20, 50, 100, 200, 350],
            }"
          >
            <template slot="footer" slot-scope="{ props }">
              <Pagination
                @reload="fetch(page)"
                @changeLimit="
                  limit = $event;
                  fetch();
                "
                @changePage="
                  page = $event;
                  fetch($event);
                "
                :model="props"
              />
            </template>

            <template slot="item" slot-scope="{ item }">
              <tr class="mx-3 px-3 box-shadow">
                <td class="text-center white">
                  {{ item.company.name }}
                </td>
                <td class="text-center white">
                  {{ item.data.sync_member_redeem.program.title }}
                </td>
                <td class="text-start white">
                  {{ item.data.sync_member_redeem.topic.name }}
                </td>
                <td class="text-start white">
                  {{ item.data.sync_member_redeem.activity.title }}
                </td>
                <td class="text-center white">
                  {{ item.created_at | datetime }}
                </td>
                <td class="text-center white">
                  {{ item.running_at | datetime }}
                </td>
                <td class="text-center white">
                  <v-chip v-if="item.status == 'pending'" outlined color="error"
                    >Pending</v-chip
                  >
                  <v-chip
                    v-else-if="item.status == 'running'"
                    outlined
                    color="warning"
                    >Running</v-chip
                  >
                  <v-chip
                    v-else-if="item.status == 'success'"
                    outlined
                    color="success"
                    >Success</v-chip
                  >
                </td>
                <td class="text-center white">
                  <v-menu
                    left
                    offset-x>
                    <template v-slot:activator="{ on, attrs }">
                      <v-icon
                        v-bind="attrs"
                        v-on="on">
                        mdi-dots-vertical-circle-outline
                      </v-icon>
                    </template>

                    <v-list dense  class="pa-0 rounded-lg">
                      <v-list-item @click="resetCronConfirm(item.id)">
                        <v-list-item-icon class="mr-4">
                          <v-icon color="error">mdi-refresh</v-icon>
                        </v-list-item-icon>
                        <v-list-item-content>
                          <v-list-item-title>Reset Cron</v-list-item-title>
                        </v-list-item-content>
                      </v-list-item>
                      <v-divider></v-divider>
                      <v-list-item @click="showDetail(item)">
                        <v-list-item-icon class="mr-4">
                          <v-icon color="green">mdi-file-document-outline</v-icon>
                        </v-list-item-icon>
                        <v-list-item-content>
                          <v-list-item-title>Detail Log Cron</v-list-item-title>
                        </v-list-item-content>
                      </v-list-item>
                    </v-list>
                  </v-menu>
                  <!-- <div class="text-no-wrap">
                    <v-tooltip bottom>
                      <template v-slot:activator="{ on, attrs }">
                        <v-btn
                          :disabled="item.status != 'running'"
                          icon
                          v-bind="attrs"
                          v-on="on"
                          @click="resetCron(item)"
                        >
                          <v-icon>mdi-refresh</v-icon>
                        </v-btn>
                      </template>
                      <span>Reset</span>
                    </v-tooltip>
                    <v-tooltip bottom>
                      <template v-slot:activator="{ on, attrs }">
                        <v-btn
                          icon
                          v-bind="attrs"
                          v-on="on"
                          @click="showDetail(item)"
                        >
                          <v-icon>mdi-file-document-outline</v-icon>
                        </v-btn>
                      </template>
                      <span>Detail Cron</span>
                    </v-tooltip>
                  </div> -->
                </td>
              </tr>
            </template>
          </v-data-table>
        </v-skeleton-loader>
      </div>
    </div>
  </div>
</template>

<script>
import { get, post, put, destroy } from "@/service/Axios";
import GridTable from "@/components/GridTable";
import Pagination from "@/components/Pagination.vue";
import Breadcrumbs from "@/components/Breadcrumbs.vue";
import Snackbar from "@/components/Snackbar.vue";
export default {
  components: { GridTable, Pagination, Breadcrumbs, Snackbar },
  data() {
    return {
      title: "List Users",
      limit: 10,
      page: 1,
      selected: {
        pagination: 0,
      },
      headers: [
        { text: "Company", align: "center", value: "0", sortable: false },
        { text: "Program Name", align: "center", value: "1", sortable: false },
        { text: "Topic Name", align: "center", value: "2", sortable: false },
        { text: "Activity", align: "center", value: "3", sortable: false },
        {
          text: "Created at",
          align: "center",
          value: "4",
          sortable: false,
        },
        {
          text: "Running Date",
          align: "center",
          value: "5",
          sortable: false,
        },
        { text: "Status", align: "center", value: "6", sortable: false },
        { text: "Actions", align: "center", value: "7", sortable: false },
      ],
      items: {},
      member: {},
      searching: "",
      process: {
        run: false,
      },
      id: "",
      formFilter: {
        member_id: "",
        license: "",
        code: "",
        order_id: "",
        is_used: "",
        is_sold: "",
      },
      dialog: {
        filter: false,
        confirm: false,
        detail: false,
      },
      dialog_generate: false,
      form: {},
      company: {},
      order: {},
      program: {},
      merchant: {},
    };
  },
  watch: {
    "selected.pagination": function (val) {
      this.fetch(val + 1);
    },
    searching: _.debounce(function (newVal) {
      this.searching = newVal;
      this.fetch();
    }, 500),
  },
  created() {
    this.fetch();
    this.fetchMember();
  },
  mounted() {},
  methods: {
    changePage(event) {
      if (event === undefined) {
        this.selected.pagination = 0;
      }
    },
    filter() {
      this.dialog.filter = true;
      // this.$refs.snackbar.open("#4CAF50", `Mohon Maaf Fitur Belum Tersedia`);
    },
    closeFilter(state) {
      this.dialog.filter = false;
      if (state != "close") {
        this.formFilter = {
          member_id: "",
          license: "",
          code: "",
          order_id: "",
          is_used: "",
          is_sold: "",
        };
        this.fetch();
      }
    },
    async fetch(paging) {
      let _self = this;
      _self.process.run = true;

      await get(`cron/v1/list`, {
        params: {
          limit: this.limit,
          page: paging,
          q: this.searching,
          user_id: this.formFilter.member_id,
          code: this.formFilter.code,
          order_id: this.formFilter.order_id,
          is_used: this.formFilter.is_used,
          is_sold: this.formFilter.is_sold,
        },
      }).then((response) => {
        let res = response.data;
        this.items = res.data;
        this.process.run = false;
        this.$store.state.process.run = false;
        this.totalData = res.data.total;
        this.closeFilter("close");
      });
    },
    async fetchMember(paging) {
      let _self = this;
      _self.process.run = true;

      await get(`users/v1/superuser/member`, {
        params: {
          limit: this.limit,
          page: paging,
          sort: "created_at",
          dir: "asc",
          fullname: this.searching,
        },
      }).then((response) => {
        let res = response.data;
        this.member = res.data;
        this.process.run = false;
        this.$store.state.process.run = false;
        this.totalData = res.data.total_data;
      });
    },
    showDialog() {
      this.dialog_generate = true;
      this.fetchCompany();
    },
    async fetchCompany(paging) {
      let _self = this;
      _self.process.run = true;

      await get(`company/v1/superuser/list`).then((response) => {
        let res = response.data;
        this.company = res.data;
        this.process.run = false;
        this.$store.state.process.run = false;
      });
    },
    async fetchOrder(paging) {
      let _self = this;
      _self.process.run = true;

      await get(
        `license/v1/superuser/list?program_id=${this.form.program_id}&company_id=${this.form.company_id}`
      )
        .then((response) => {
          let res = response.data;
          this.order = res.data;
          this.$store.state.process.run = false;
        })
        .finally(() => {
          this.process.run = false;
        });
    },
    async fetchProgram() {
      let _self = this;
      _self.process.run = true;

      await get(
        `program/v1/superuser/list?company_id=${this.form.company_id}`
      ).then((response) => {
        let res = response.data;
        this.program = res.data;
        this.process.run = false;
        this.$store.state.process.run = false;
        this.fetchMerchant();
      });
    },
    async fetchMerchant() {
      let _self = this;
      _self.process.run = true;

      await get(
        `merchant/v1/superuser/list?company_id=${this.form.company_id}`
      ).then((response) => {
        let res = response.data;
        this.merchant = res.data;
        this.process.run = false;
        this.$store.state.process.run = false;
      });
    },
    getGenerate() {
      let _self = this;
      _self.process.run = true;
      post(`license/v1/superuser/generate`, {
        data: this.form,
      })
        .then((response) => {
          let res = response.data;
          if (res.status) {
            this.$refs.snackbar.open("#4CAF50", `Berhasil Generate Voucher`);
            this.dialog_generate = false;
            this.form = {};
            this.fetch();
          } else {
            this.$refs.snackbar.open("#D32F2F", `Gagal Generate Voucher`);
          }
          this.process.run = false;
        })
        .catch(() => {
          this.process.run = false;
        });
    },
    resetCronConfirm(id) {
      this.dialog.confirm = true;
      this.id = id;
    },
    async resetCron() {
      this.process.run = true;
      await post(`cron/v1/reset`, {
        data: {
          id: this.id,
        },
      })
        .then((response) => {
          let res = response.data;
          if (res.status) {
            this.$refs.snackbar.open("#4CAF50", `Berhasil Reset Cron`);
            this.fetch();
          } else {
            this.$refs.snackbar.open("#D32F2F", `Gagal Reset Cron`);
          }
          this.dialog.confirm = false;
          this.process.run = false;
        })
        .catch(() => {
          this.process.run = false;
          this.dialog.confirm = false;
        });
    },
    showDetail(item) {
      this.id = item;
      this.dialog.detail = true;
    },
  },
};
</script>

<style>
</style>